@import "../core/functions";

.hero {
  --hero-height: 90rem;
  --hero-space: 25rem;
  --hero-content-width: 80rem;
  background-color: var(--pa-color-primary);
  padding: 5rem 2rem var(--hero-space) var(--hero-space);
  border-radius: 40px;
  position: relative;
  //height: 70vh;
  min-height: var(--hero-height);
  display: flex;
  align-items: flex-end;
  margin-top: 3rem;
  word-wrap: normal;
  @include bp-max($retina-width) {
    --hero-space: 12rem;
  }
  @include bp-max($mq-large) {
    --hero-height: 70rem;
    --hero-space: 9rem;
  }
  @include bp-max($mq-xl) {
    --hero-space: 5rem;
  }
  @include bp-max($mq-lg) {
    flex-direction: column;
    align-items: flex-start;
    padding: 5rem var(--hero-space);
  }
  @include bp-max($mq-md) {
    padding: 4rem 2.9rem;
    border-radius: 30px;
  }
  &-content {
    position: relative;
    z-index: 1;
    @include bp-min($mq-lg) {
      max-width: var(--hero-content-width);
      width: 55%;
    }
    @include bp-max($mq-lg) {
      padding-top: 1.5rem;
    }
  }
  .title {
    @include fluid-type($mq-md, $mq-large, 40px, 80px);
    color: #fff;
    line-height: 1.05;
    margin-bottom: 2rem;
    span {
      color: var(--pa-color-secondary);
    }
    br {
      @include bp-max($mq-xl) {
        //display: none;
      }
    }
  }
  .text {
    color: #fff;
    span {
      color: var(--pa-color-secondary);
    }
  }
  .pa-btn {
    margin-top: 3.5rem;
  }
  picture {
    @include bp-max($mq-lg) {
      max-width: 50rem;
      order: -1;
    }
  }
  img {
    object-fit: contain;
    object-position: top right;
    max-height: 100%;
    @include bp-min($mq-lg) {
      position: absolute;
      top: -5rem;
      right: -6rem;
    }
    @include bp-max($mq-lg) {
      order: -1;
    }
  }
  &.secondary {
    @include bp-min($mq-xl) {
      --hero-height: 65.5rem;
      --hero-content-width: 60rem;
      padding-bottom: 7rem;
    }
    @include bp-min($retina-width) {
      --hero-space: 17rem;
    }
  }
}