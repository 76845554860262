@import "../core/functions";

.products-promo {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 7rem 0 0;
  margin-bottom: 6rem;
  @include bp-min($mq-md) {
    margin: 7rem auto 10rem;
  }
  @include bp-min($mq-xxl) {
    max-width: calc(100% - 18rem);
  }
  .item {
    --product-item-space-m-x: 4rem;
    --product-item-space-p-x: 8rem;
    --product-item-height: 30rem;
    --product-item-width-hor: 24rem;
    border-radius: 40px;
    background-color: var(--pa-color-primary--light);
    display: flex;
    word-wrap: normal;
    position: relative;
    min-height: var(--product-item-height);
    @include bp-max($mq-large) {
      --product-item-space-p-x: 4rem;
      --product-item-space-m-x: 2.5rem;
      --product-item-height: 27rem;
    }
    @include bp-max($mq-md) {
      --product-item-width-hor: 0.000001px;
      --product-item-space-m-x: 0.000001px;
    }
    &:nth-child(1),
    &:nth-child(2) {
      --item-percent: 1;
      width: calc((100% - var(--product-item-width-hor))/var(--item-percent) - var(--product-item-space-m-x));
      margin: 0 var(--product-item-space-m-x) 14rem 0;
      @include bp-max($mq-large) {
        margin-bottom: 10rem;
      }
      @include bp-max($mq-xl) {
        margin-bottom: 5rem;
      }
      @include bp-min($mq-xl) {
        --item-percent: 2;
      }
    }
    &:nth-child(1) {
      //
    }
    &:nth-child(2) {
      background-color: var(--pa-color-secondary--dark);
    }
    &:nth-child(3) {
      background-color: var(--pa-color-primary);
      width: var(--product-item-width-hor);
      @include bp-max($mq-xl) {
        max-height: 60%;
      }
      @include bp-min($mq-md) {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        min-height: 50rem;
      }
      @include bp-max($mq-md) {
        width: 100%;
        max-width: 400px;
        max-height: unset;
        order: 10;
        margin-top: 5rem;
      }
      a {
        flex-direction: column;
        padding: 4rem 3rem;
      }
      .content {
        max-width: 100%;
        margin-top: auto;
      }
      .img {
        order: -1;
        margin-bottom: 1rem;
        text-align: center;
        flex-grow: 1;
        max-width: 100%;
        max-height: 65%;
        img {
          position: static;
          max-height: 100%;
          max-width: 100%;
          object-position: center;
        }
      }
    }
    &:nth-child(4),
    &:nth-child(5) {
      --item-percent: 1;
      width: calc((100% - var(--product-item-width-hor))*var(--item-percent) - var(--product-item-space-m-x));
      margin: 0 var(--product-item-space-m-x) 0 0;
    }
    &:nth-child(4) {
      background-color: var(--pa-color-primary);
      @include bp-min($mq-xl) {
        --item-percent: 0.413;
      }
      @include bp-max($mq-xl) {
        margin-bottom: 5rem;
      }
      .img {
        img {
          max-height: calc(100% + 2rem);
        }
      }
    }
    &:nth-child(5) {
      @include bp-min($mq-xl) {
        --item-percent: 0.587;
      }
      @include bp-max($mq-large) {
        //
      }
    }
    a {
      padding: 6rem var(--product-item-space-p-x) 4rem;
      width: 100%;
      display: flex;
      @include bp-min($mq-sm) {
        align-items: flex-end;
      }
      @include bp-max($mq-sm) {
        flex-direction: column;
      }
    }
  }
  .content {
    width: 100%;
    position: relative;
    z-index: 1;
    @include bp-min($mq-sm) {
      max-width: 40%;
      padding-right: 1.5rem;
    }
  }
  .title {
    @include fluid-type($mq-md, $mq-large, 24px, 40px);
    line-height: 1;
    color: #fff;
    font-family: var(--pa-font-base--700);
    margin-bottom: 1rem;
  }
  .quantity {
    font-size: 1.4rem;
    color: var(--pa-color-text);
    font-family: var(--pa-font-base--500);
  }
  .pa-btn {
    margin-top: 4rem;
  }
  .img {
    width: 100%;
    @include bp-min($mq-sm) {
      max-width: 60%;
    }
    @include bp-max($mq-sm) {
      order: -1;
      margin-bottom: 2rem;
      text-align: center;
    }
    img {
      width: auto;
      object-fit: contain;
      object-position: top right;
      @include bp-min($mq-sm) {
        position: absolute;
        bottom: 0;
        right: 3rem;
        max-height: calc(100% + 4rem);
        max-width: 60%;
      }
      @include bp-min($mq-xl) {
        max-height: calc(100% + 7rem);
      }
      @include bp-max($mq-large) {
        //max-height: 330px;
      }
    }
  }
}