@import "modules/hero";
@import "modules/products-promo";

body.cms-home  {
  background-color: var(--pa-color-secondary--light);
  .page-main {
    overflow: hidden;
  }
  .column.main {
    max-width: var(--pa-container-size);
    padding-left: var(--pa-container-space-x);
    padding-right: var(--pa-container-space-x);
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10rem;
  }
}